// Copyright 2016 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

@import "../../common/variables";
@import "~@blueprintjs/icons/lib/scss/variables";
@import "./common";

.#{$ns}-submenu {
  > .#{$ns}-popover-wrapper {
    display: block;
  }

  .#{$ns}-popover-target {
    display: block;

    &.#{$ns}-popover-open > .#{$ns}-menu-item {
      // keep a trail of hovered items as submenus are opened
      /* stylelint-disable scss/at-extend-no-missing-placeholder */
      &:not([class*="#{$ns}-intent-"]) {
        @extend .#{$ns}-menu-item:hover;
      }

      &[class*="#{$ns}-intent-"] {
        @extend .#{$ns}-menu-item.#{$ns}-selected;
      }
      /* stylelint-enable scss/at-extend-no-missing-placeholder */
    }
  }

  &.#{$ns}-popover {
    box-shadow: none;
    // horizontal padding leaves some space from parent menu item, and extends mouse zone
    padding: 0 $half-grid-size;

    > .#{$ns}-popover-content {
      box-shadow: $pt-popover-box-shadow;
    }

    .#{$ns}-dark &,
    &.#{$ns}-dark {
      box-shadow: none;

      > .#{$ns}-popover-content {
        box-shadow: $pt-dark-popover-box-shadow;
      }
    }
  }
}
