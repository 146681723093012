// Copyright 2016 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

$half-grid-size: $pt-grid-size / 2 !default;

$menu-item-border-radius: $pt-border-radius - 1 !default;

// Set line-height of menu items to be a multiple of the font size. This is
// needed because if the line-height does not extend far enough past the font's
// baseline, clipping will occur when the .#{$ns}-text-overflow-ellipsis class is
// applied to it (#2177). Also, line-height should be an even value, or content
// will be misaligned by one pixel (Chrome quirk).
$menu-item-line-height-factor: 1.4;
$menu-item-line-height: round($pt-font-size * $menu-item-line-height-factor);
$menu-item-line-height-large: round($pt-font-size-large * $menu-item-line-height-factor);

$menu-min-width: $pt-grid-size * 18 !default;
$menu-item-padding: ($pt-button-height - $pt-icon-size-standard) / 2 !default;
$menu-item-padding-large: ($pt-button-height-large - $pt-icon-size-large) / 2 !default;
$menu-item-padding-vertical: ($pt-button-height - $menu-item-line-height) / 2 !default;
$menu-item-padding-vertical-large:
  ($pt-button-height-large - $menu-item-line-height-large) / 2 !default;

$menu-background-color: $white !default;
$dark-menu-background-color: $dark-gray4 !default;

$menu-item-color-hover: rgba($gray3, 0.15) !default;
$menu-item-color-active: rgba($gray3, 0.3) !default;

$menu-item-intent-colors: (
  "primary": (/* bg */ $blue3, /* fg */ $blue2, /* fg active */ $blue1),
  "success": (/* bg */ $green3, /* fg */ $green2, /* fg active */ $green1),
  "warning": (/* bg */ $orange3, /* fg */ $orange2, /* fg active */ $orange1),
  "danger": (/* bg */ $red3, /* fg */ $red2, /* fg active */ $red1),
) !default;

$dark-menu-item-intent-colors: (
  "primary": (/* bg */ $blue3, /* fg */ $blue5, /* fg active */ $blue6),
  "success": (/* bg */ $green3, /* fg */ $green5, /* fg active */ $green6),
  "warning": (/* bg */ $orange3, /* fg */ $orange5, /* fg active */ $orange6),
  "danger": (/* bg */ $red3, /* fg */ $red5, /* fg active */ $red6),
) !default;

@mixin menu-item() {
  @include pt-flex-container(row, $menu-item-padding);
  align-items: flex-start;
  border-radius: $menu-item-border-radius;
  color: inherit;
  line-height: $menu-item-line-height;
  padding: $menu-item-padding-vertical $menu-item-padding;
  text-decoration: none;
  user-select: none;

  > .#{$ns}-fill {
    word-break: break-word;
  }

  .#{$ns}-menu-item-icon {
    display: flex;
    flex-direction: column;
    height: $menu-item-line-height;
    justify-content: center;
  }

  .#{$ns}-menu-item-label {
    color: $pt-text-color-muted;
  }

  &::before,
  .#{$ns}-menu-item-icon,
  .#{$ns}-submenu-icon {
    color: $pt-icon-color;
  }

  &::before,
  .#{$ns}-submenu-icon {
    margin-top: ($menu-item-line-height - $pt-icon-size-standard) / 2;
  }

  &:hover {
    background-color: $menu-item-color-hover;
    color: inherit;
    cursor: pointer;
    text-decoration: none;
  }

  &:active,
  &.#{$ns}-active {
    background-color: $menu-item-color-active;

    .#{$ns}-menu-item-label {
      color: $pt-text-color;
    }
  }

  &.#{$ns}-selected {
    &,
    &:hover,
    &:active {
      background-color: rgba(nth(map-get($menu-item-intent-colors, "primary"), 1), 0.1);
      color: nth(map-get($menu-item-intent-colors, "primary"), 2);

      &::before,
      .#{$ns}-menu-item-icon,
      .#{$ns}-submenu-icon {
        color: nth(map-get($menu-item-intent-colors, "primary"), 2);
      }

      .#{$ns}-menu-item-label {
        color: inherit;
      }

      @each $intent in ("success", "warning", "danger") {
        &.#{$ns}-intent-#{$intent} {
          background-color: rgba(nth(map-get($menu-item-intent-colors, $intent), 1), 0.1);
          color: nth(map-get($menu-item-intent-colors, $intent), 2);

          &::before,
          .#{$ns}-menu-item-icon,
          .#{$ns}-submenu-icon {
            color: inherit;
          }
        }
      }
    }
  }

  // pt-disable always overrides over styles
  /* stylelint-disable declaration-no-important */
  &.#{$ns}-disabled {
    background-color: inherit !important;
    color: $pt-text-color-disabled !important;
    cursor: not-allowed !important;
    // override global a:focus styles
    outline: none !important;

    &::before,
    .#{$ns}-menu-item-icon,
    .#{$ns}-submenu-icon {
      color: $pt-icon-color-disabled !important;
    }

    .#{$ns}-menu-item-label {
      color: $pt-text-color-disabled !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}

@mixin dark-menu-item() {
  color: inherit;

  .#{$ns}-menu-item-label {
    color: $pt-dark-text-color-muted;
  }

  &::before,
  .#{$ns}-menu-item-icon,
  .#{$ns}-submenu-icon {
    color: $pt-dark-icon-color;
  }

  &:hover {
    color: inherit;

    // need to override typography styles here
    .#{$ns}-menu-item-icon,
    .#{$ns}-submenu-icon {
      color: $pt-dark-icon-color;
    }
  }

  &:active,
  &.#{$ns}-active {
    .#{$ns}-menu-item-label {
      color: $pt-dark-text-color;
    }
  }

  &.#{$ns}-selected {
    &,
    &:hover,
    &:active {
      background-color: rgba(nth(map-get($dark-menu-item-intent-colors, "primary"), 1), 0.2);
      color: nth(map-get($dark-menu-item-intent-colors, "primary"), 2);

      &::before,
      .#{$ns}-menu-item-icon,
      .#{$ns}-submenu-icon {
        color: nth(map-get($dark-menu-item-intent-colors, "primary"), 2);
      }

      @each $intent in ("success", "warning", "danger") {
        &.#{$ns}-intent-#{$intent} {
          background-color: rgba(nth(map-get($dark-menu-item-intent-colors, $intent), 1), 0.2);
          color: nth(map-get($dark-menu-item-intent-colors, $intent), 2);

          &::before,
          .#{$ns}-menu-item-icon,
          .#{$ns}-submenu-icon {
            color: inherit;
          }
        }
      }
    }
  }

  // pt-disable always overrides over styles
  /* stylelint-disable declaration-no-important */
  &.#{$ns}-disabled {
    color: $pt-dark-text-color-disabled !important;

    &::before,
    .#{$ns}-menu-item-icon,
    .#{$ns}-submenu-icon {
      color: $pt-dark-icon-color-disabled !important;
    }

    .#{$ns}-menu-item-label {
      color: $pt-dark-text-color-disabled !important;
    }
  }
  /* stylelint-enable declaration-no-important */
}

@mixin menu-item-intent($intent, $is-dark, $bg-color, $fg-color, $fg-color-active) {
  &.#{$ns}-intent-#{$intent} {
    color: $fg-color;

    &::before,
    .#{$ns}-menu-item-icon,
    .#{$ns}-submenu-icon,
    .#{$ns}-menu-item-label {
      color: inherit;
    }

    &:hover {
      @if $is-dark {
        background-color: rgba($bg-color, 0.2);
      } @else {
        background-color: rgba($bg-color, 0.1);
      }
    }

    &:active,
    &.#{$ns}-active {
      @if $is-dark {
        background-color: rgba($bg-color, 0.3);
      } @else {
        background-color: rgba($bg-color, 0.2);
      }
      color: $fg-color-active;
    }
  }
}

@mixin menu-item-large() {
  font-size: $pt-font-size-large;
  line-height: $menu-item-line-height-large;
  padding: $menu-item-padding-vertical-large $menu-item-padding;

  .#{$ns}-menu-item-icon {
    height: $menu-item-line-height-large;
  }

  &::before,
  .#{$ns}-submenu-icon {
    // SVG icons remain standard size when menu is large
    margin-top: ($menu-item-line-height-large - $pt-icon-size-standard) / 2;
  }
}

@mixin menu-divider() {
  border-top: 1px solid $pt-divider-black;
  display: block;
  margin: $half-grid-size;

  .#{$ns}-dark & {
    border-top-color: $pt-dark-divider-white;
  }
}

@mixin menu-header($heading-selector: null) {
  @include menu-divider();
  cursor: default;
  padding-left: $menu-item-padding - $half-grid-size;

  @if $heading-selector != null {
    &:first-of-type {
      border-top: none;
    }

    #{$heading-selector} {
      @include menu-heading();
    }

    &:first-of-type #{$heading-selector} {
      padding-top: 0;
    }
  }
}

@mixin menu-heading() {
  @include heading-typography();
  @include overflow-ellipsis();
  // a little extra space to avoid clipping descenders (because overflow hidden)
  line-height: $pt-icon-size-standard + 1px;
  margin: 0;
  padding: $pt-grid-size $menu-item-padding 0 1px;
}

@mixin menu-header-large($heading-selector) {
  #{$heading-selector} {
    font-size: $pt-grid-size * 1.8;
    padding-bottom: $pt-grid-size / 2;
    padding-top: $pt-grid-size * 1.5;
  }

  &:first-of-type #{$heading-selector} {
    padding-top: 0;
  }
}
