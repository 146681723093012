$blueprint-icons-16-font: "blueprint-icons-16";

$blueprint-icons-16-codepoints: (
    "blank": "\f133",
    "style": "\f2d4",
    "align-left": "\f10d",
    "align-center": "\f10b",
    "align-right": "\f10e",
    "align-justify": "\f10c",
    "bold": "\f135",
    "italic": "\f20a",
    "underline": "\f301",
    "search-around": "\f29f",
    "graph-remove": "\f1d4",
    "group-objects": "\f1da",
    "merge-links": "\f242",
    "layout": "\f225",
    "layout-auto": "\f21c",
    "layout-circle": "\f21e",
    "layout-hierarchy": "\f221",
    "layout-grid": "\f21f",
    "layout-group-by": "\f220",
    "layout-skew-grid": "\f223",
    "geosearch": "\f1c9",
    "heatmap": "\f1ea",
    "drive-time": "\f192",
    "select": "\f2a4",
    "predictive-analysis": "\f27c",
    "layers": "\f21b",
    "locate": "\f231",
    "bookmark": "\f137",
    "citation": "\f154",
    "tag": "\f2df",
    "clipboard": "\f157",
    "selection": "\f2a5",
    "timeline-events": "\f2f3",
    "timeline-line-chart": "\f2f4",
    "timeline-bar-chart": "\f2f2",
    "applications": "\f119",
    "projects": "\f280",
    "changes": "\f146",
    "notifications": "\f25e",
    "lock": "\f232",
    "unlock": "\f305",
    "user": "\f30a",
    "search-template": "\f2a0",
    "inbox": "\f1ff",
    "more": "\f24a",
    "help": "\f1ec",
    "calendar": "\f13e",
    "media": "\f23d",
    "link": "\f22d",
    "share": "\f2b1",
    "download": "\f18a",
    "document": "\f180",
    "properties": "\f281",
    "import": "\f1fa",
    "export": "\f1a0",
    "minimize": "\f243",
    "maximize": "\f23c",
    "tick": "\f2ef",
    "cross": "\f169",
    "plus": "\f279",
    "minus": "\f244",
    "arrow-left": "\f125",
    "arrow-right": "\f126",
    "exchange": "\f19d",
    "comparison": "\f161",
    "list": "\f230",
    "filter": "\f1ad",
    "confirm": "\f164",
    "fork": "\f1c0",
    "trash": "\f2fa",
    "person": "\f272",
    "people": "\f270",
    "add": "\f109",
    "remove": "\f290",
    "geolocation": "\f1c8",
    "zoom-in": "\f31e",
    "zoom-out": "\f31f",
    "refresh": "\f289",
    "delete": "\f175",
    "cog": "\f15d",
    "flag": "\f1ae",
    "pin": "\f275",
    "warning-sign": "\f315",
    "error": "\f19b",
    "info-sign": "\f200",
    "credit-card": "\f168",
    "edit": "\f194",
    "history": "\f1f0",
    "search": "\f2a2",
    "log-out": "\f234",
    "star": "\f2cd",
    "star-empty": "\f2cc",
    "sort-alphabetical": "\f2c2",
    "sort-numerical": "\f2c6",
    "sort": "\f2c7",
    "folder-open": "\f1ba",
    "folder-close": "\f1b8",
    "folder-shared": "\f1bc",
    "caret-up": "\f143",
    "caret-right": "\f142",
    "caret-down": "\f140",
    "caret-left": "\f141",
    "menu-open": "\f23f",
    "menu-closed": "\f23e",
    "feed": "\f1a7",
    "two-columns": "\f2ff",
    "one-column": "\f264",
    "dot": "\f182",
    "property": "\f282",
    "time": "\f2f0",
    "disable": "\f17d",
    "unpin": "\f306",
    "flows": "\f1b7",
    "new-text-box": "\f259",
    "new-link": "\f255",
    "new-object": "\f256",
    "path-search": "\f26d",
    "automatic-updates": "\f12d",
    "page-layout": "\f268",
    "code": "\f15c",
    "map": "\f23b",
    "search-text": "\f2a1",
    "envelope": "\f198",
    "paperclip": "\f26b",
    "label": "\f218",
    "globe": "\f1d3",
    "home": "\f1f1",
    "th": "\f2ea",
    "th-list": "\f2e9",
    "th-derived": "\f2e6",
    "circle": "\f153",
    "draw": "\f18d",
    "insert": "\f204",
    "helper-management": "\f1ed",
    "send-to": "\f2a9",
    "eye-open": "\f1a3",
    "folder-shared-open": "\f1bb",
    "social-media": "\f2c0",
    "arrow-up": "\f129",
    "arrow-down": "\f124",
    "arrows-horizontal": "\f12a",
    "arrows-vertical": "\f12b",
    "resolve": "\f293",
    "graph": "\f1d5",
    "briefcase": "\f139",
    "dollar": "\f181",
    "ninja": "\f25a",
    "delta": "\f176",
    "barcode": "\f132",
    "torch": "\f2f6",
    "widget": "\f31b",
    "unresolve": "\f307",
    "offline": "\f262",
    "zoom-to-fit": "\f320",
    "add-to-artifact": "\f107",
    "map-marker": "\f23a",
    "chart": "\f147",
    "control": "\f167",
    "multi-select": "\f24e",
    "direction-left": "\f17b",
    "direction-right": "\f17c",
    "database": "\f174",
    "pie-chart": "\f274",
    "full-circle": "\f1c2",
    "square": "\f2c9",
    "print": "\f27f",
    "presentation": "\f27e",
    "ungroup-objects": "\f303",
    "chat": "\f148",
    "comment": "\f160",
    "circle-arrow-right": "\f151",
    "circle-arrow-left": "\f150",
    "circle-arrow-up": "\f152",
    "circle-arrow-down": "\f14f",
    "upload": "\f309",
    "asterisk": "\f12c",
    "cloud": "\f15a",
    "cloud-download": "\f158",
    "cloud-upload": "\f159",
    "repeat": "\f291",
    "move": "\f24c",
    "chevron-left": "\f14c",
    "chevron-right": "\f14d",
    "chevron-up": "\f14e",
    "chevron-down": "\f14a",
    "random": "\f286",
    "fullscreen": "\f1c4",
    "log-in": "\f233",
    "heart": "\f1e8",
    "office": "\f261",
    "duplicate": "\f193",
    "ban-circle": "\f130",
    "camera": "\f13f",
    "mobile-video": "\f246",
    "video": "\f30f",
    "film": "\f1a8",
    "settings": "\f2af",
    "volume-off": "\f312",
    "volume-down": "\f311",
    "volume-up": "\f313",
    "music": "\f24f",
    "step-backward": "\f2ce",
    "fast-backward": "\f1a4",
    "pause": "\f26f",
    "stop": "\f2d1",
    "play": "\f278",
    "fast-forward": "\f1a5",
    "step-forward": "\f2d0",
    "eject": "\f195",
    "record": "\f287",
    "desktop": "\f178",
    "phone": "\f273",
    "lightbulb": "\f22b",
    "glass": "\f1d1",
    "tint": "\f2f5",
    "flash": "\f1b0",
    "font": "\f1bf",
    "header": "\f1e5",
    "saved": "\f29d",
    "floppy-disk": "\f1b1",
    "book": "\f136",
    "hand-right": "\f1de",
    "hand-up": "\f1df",
    "hand-down": "\f1dc",
    "hand-left": "\f1dd",
    "thumbs-up": "\f2ed",
    "thumbs-down": "\f2ec",
    "box": "\f138",
    "compressed": "\f163",
    "shopping-cart": "\f2b6",
    "shop": "\f2b5",
    "layout-linear": "\f222",
    "undo": "\f302",
    "redo": "\f288",
    "code-block": "\f15b",
    "double-caret-vertical": "\f184",
    "double-caret-horizontal": "\f183",
    "sort-alphabetical-desc": "\f2c1",
    "sort-numerical-desc": "\f2c5",
    "take-action": "\f2e0",
    "contrast": "\f166",
    "eye-off": "\f1a1",
    "timeline-area-chart": "\f2f1",
    "doughnut-chart": "\f189",
    "layer": "\f21a",
    "grid": "\f1d9",
    "polygon-filter": "\f27a",
    "add-to-folder": "\f108",
    "layout-balloon": "\f21d",
    "layout-sorted-clusters": "\f224",
    "sort-asc": "\f2c3",
    "sort-desc": "\f2c4",
    "small-cross": "\f2ba",
    "small-tick": "\f2be",
    "power": "\f27b",
    "column-layout": "\f15f",
    "arrow-top-left": "\f127",
    "arrow-top-right": "\f128",
    "arrow-bottom-right": "\f123",
    "arrow-bottom-left": "\f122",
    "mugshot": "\f24d",
    "headset": "\f1e6",
    "text-highlight": "\f2e5",
    "hand": "\f1e0",
    "chevron-backward": "\f149",
    "chevron-forward": "\f14b",
    "rotate-document": "\f299",
    "rotate-page": "\f29a",
    "badge": "\f12f",
    "grid-view": "\f1d8",
    "function": "\f1c5",
    "waterfall-chart": "\f316",
    "stacked-chart": "\f2ca",
    "pulse": "\f284",
    "new-person": "\f257",
    "exclude-row": "\f19e",
    "pivot-table": "\f276",
    "segmented-control": "\f2a3",
    "highlight": "\f1ef",
    "filter-list": "\f1aa",
    "cut": "\f16f",
    "annotation": "\f115",
    "pivot": "\f277",
    "ring": "\f296",
    "heat-grid": "\f1e9",
    "gantt-chart": "\f1c6",
    "variable": "\f30b",
    "manual": "\f235",
    "add-row-top": "\f106",
    "add-row-bottom": "\f105",
    "add-column-left": "\f102",
    "add-column-right": "\f103",
    "remove-row-top": "\f28f",
    "remove-row-bottom": "\f28e",
    "remove-column-left": "\f28b",
    "remove-column-right": "\f28c",
    "double-chevron-left": "\f186",
    "double-chevron-right": "\f187",
    "double-chevron-up": "\f188",
    "double-chevron-down": "\f185",
    "key-control": "\f20e",
    "key-command": "\f20d",
    "key-shift": "\f213",
    "key-backspace": "\f20c",
    "key-delete": "\f20f",
    "key-escape": "\f211",
    "key-enter": "\f210",
    "calculator": "\f13d",
    "horizontal-bar-chart": "\f1f4",
    "small-plus": "\f2bc",
    "small-minus": "\f2bb",
    "step-chart": "\f2cf",
    "euro": "\f19c",
    "drag-handle-vertical": "\f18c",
    "drag-handle-horizontal": "\f18b",
    "mobile-phone": "\f245",
    "sim-card": "\f2b8",
    "trending-up": "\f2fd",
    "trending-down": "\f2fc",
    "curved-range-chart": "\f16e",
    "vertical-bar-chart-desc": "\f30d",
    "horizontal-bar-chart-desc": "\f1f3",
    "document-open": "\f17e",
    "document-share": "\f17f",
    "horizontal-distribution": "\f1f5",
    "vertical-distribution": "\f30e",
    "alignment-left": "\f111",
    "alignment-vertical-center": "\f114",
    "alignment-right": "\f112",
    "alignment-top": "\f113",
    "alignment-horizontal-center": "\f110",
    "alignment-bottom": "\f10f",
    "git-pull": "\f1ce",
    "git-merge": "\f1cc",
    "git-branch": "\f1ca",
    "git-commit": "\f1cb",
    "git-push": "\f1cf",
    "build": "\f13c",
    "symbol-circle": "\f2d8",
    "symbol-square": "\f2db",
    "symbol-diamond": "\f2da",
    "symbol-cross": "\f2d9",
    "symbol-triangle-up": "\f2dd",
    "symbol-triangle-down": "\f2dc",
    "wrench": "\f31d",
    "application": "\f118",
    "send-to-graph": "\f2a7",
    "send-to-map": "\f2a8",
    "join-table": "\f20b",
    "derive-column": "\f177",
    "image-rotate-left": "\f1f8",
    "image-rotate-right": "\f1f9",
    "known-vehicle": "\f216",
    "unknown-vehicle": "\f304",
    "scatter-plot": "\f29e",
    "oil-field": "\f263",
    "rig": "\f294",
    "map-create": "\f239",
    "key-option": "\f212",
    "list-detail-view": "\f22f",
    "swap-vertical": "\f2d6",
    "swap-horizontal": "\f2d5",
    "numbered-list": "\f25f",
    "new-grid-item": "\f252",
    "git-repo": "\f1d0",
    "git-new-branch": "\f1cd",
    "manually-entered-data": "\f236",
    "airplane": "\f10a",
    "merge-columns": "\f241",
    "split-columns": "\f2c8",
    "dashboard": "\f171",
    "publish-function": "\f283",
    "path": "\f26e",
    "moon": "\f249",
    "remove-column": "\f28d",
    "numerical": "\f260",
    "key-tab": "\f214",
    "regression-chart": "\f28a",
    "translate": "\f2f9",
    "eye-on": "\f1a2",
    "vertical-bar-chart-asc": "\f30c",
    "horizontal-bar-chart-asc": "\f1f2",
    "grouped-bar-chart": "\f1db",
    "full-stacked-chart": "\f1c3",
    "endorsed": "\f197",
    "follower": "\f1bd",
    "following": "\f1be",
    "menu": "\f240",
    "collapse-all": "\f15e",
    "expand-all": "\f19f",
    "intersection": "\f205",
    "blocked-person": "\f134",
    "slash": "\f2b9",
    "percentage": "\f271",
    "satellite": "\f29c",
    "paragraph": "\f26c",
    "bank-account": "\f131",
    "cell-tower": "\f145",
    "id-number": "\f1f7",
    "ip-address": "\f206",
    "eraser": "\f19a",
    "issue": "\f209",
    "issue-new": "\f208",
    "issue-closed": "\f207",
    "panel-stats": "\f269",
    "panel-table": "\f26a",
    "tick-circle": "\f2ee",
    "prescription": "\f27d",
    "new-prescription": "\f258",
    "filter-keep": "\f1a9",
    "filter-remove": "\f1ac",
    "key": "\f215",
    "feed-subscribed": "\f1a6",
    "widget-button": "\f318",
    "widget-header": "\f31a",
    "widget-footer": "\f319",
    "header-one": "\f1e2",
    "header-two": "\f1e4",
    "form": "\f1c1",
    "series-add": "\f2aa",
    "series-search": "\f2ae",
    "series-filtered": "\f2ad",
    "series-derived": "\f2ac",
    "series-configuration": "\f2ab",
    "console": "\f165",
    "compass": "\f162",
    "walk": "\f314",
    "taxi": "\f2e3",
    "train": "\f2f8",
    "heart-broken": "\f1e7",
    "inner-join": "\f203",
    "left-join": "\f227",
    "right-join": "\f295",
    "strikethrough": "\f2d3",
    "updated": "\f308",
    "outdated": "\f267",
    "flame": "\f1af",
    "folder-new": "\f1b9",
    "mountain": "\f24b",
    "shield": "\f2b3",
    "diagram-tree": "\f17a",
    "crown": "\f16a",
    "globe-network": "\f1d2",
    "snowflake": "\f2bf",
    "tree": "\f2fb",
    "notifications-updated": "\f25d",
    "list-columns": "\f22e",
    "flow-linear": "\f1b4",
    "flow-branch": "\f1b2",
    "flow-review": "\f1b6",
    "flow-review-branch": "\f1b5",
    "flow-end": "\f1b3",
    "clean": "\f155",
    "th-filtered": "\f2e8",
    "lifesaver": "\f22a",
    "cube": "\f16d",
    "cube-add": "\f16b",
    "cube-remove": "\f16c",
    "inbox-filtered": "\f1fb",
    "inbox-geo": "\f1fc",
    "inbox-search": "\f1fd",
    "inbox-update": "\f1fe",
    "inheritance": "\f201",
    "reset": "\f292",
    "filter-open": "\f1ab",
    "th-disconnect": "\f2e7",
    "equals": "\f199",
    "not-equal-to": "\f25b",
    "greater-than": "\f1d7",
    "greater-than-or-equal-to": "\f1d6",
    "less-than": "\f229",
    "less-than-or-equal-to": "\f228",
    "learning": "\f226",
    "new-layer": "\f253",
    "new-layers": "\f254",
    "stopwatch": "\f2d2",
    "archive": "\f11a",
    "unarchive": "\f300",
    "data-lineage": "\f173",
    "new-drawing": "\f251",
    "signal-search": "\f2b7",
    "bring-data": "\f13a",
    "tractor": "\f2f7",
    "truck": "\f2fe",
    "diagnosis": "\f179",
    "lab-test": "\f217",
    "virus": "\f310",
    "inherited-group": "\f202",
    "hat": "\f1e1",
    "cycle": "\f170",
    "route": "\f29b",
    "modal": "\f248",
    "modal-filled": "\f247",
    "drawer-left": "\f18f",
    "drawer-left-filled": "\f18e",
    "drawer-right": "\f191",
    "drawer-right-filled": "\f190",
    "app-header": "\f117",
    "send-message": "\f2a6",
    "backlink": "\f12e",
    "geofence": "\f1c7",
    "data-connection": "\f172",
    "switch": "\f2d7",
    "array": "\f121",
    "array-boolean": "\f11c",
    "array-date": "\f11d",
    "array-numeric": "\f11e",
    "array-string": "\f11f",
    "array-timestamp": "\f120",
    "layer-outline": "\f219",
    "notifications-snooze": "\f25c",
    "high-priority": "\f1ee",
    "emoji": "\f196",
    "add-location": "\f104",
    "shapes": "\f2b0",
    "shared-filter": "\f2b2",
    "one-to-one": "\f266",
    "one-to-many": "\f265",
    "many-to-one": "\f238",
    "many-to-many": "\f237",
    "stadium-geometry": "\f2cb",
    "area-of-interest": "\f11b",
    "buggy": "\f13b",
    "antenna": "\f116",
    "tank": "\f2e1",
    "third-party": "\f2eb",
    "rocket": "\f298",
    "rocket-slant": "\f297",
    "header-three": "\f1e3",
    "helicopter": "\f1eb",
    "syringe": "\f2de",
    "temperature": "\f2e4",
    "waves": "\f317",
    "rain": "\f285",
    "lightning": "\f22c",
    "wind": "\f31c",
    "hurricane": "\f1f6",
    "nest": "\f250",
    "target": "\f2e2",
    "small-square": "\f2bd",
    "ship": "\f2b4",
    "cargo-ship": "\f144",
    "clip": "\f156",
    "add-clip": "\f101",
    "rectangle": "\f321",
    "symbol-rectangle": "\f322",
    "fuel": "\f323",
    "playbook": "\f324",
    "rect-height": "\f325",
    "rect-width": "\f326",
    "divide": "\f327",
    "color-fill": "\f328",
    "horizontal-inbetween": "\f329",
    "vertical-inbetween": "\f32a",
    "open-application": "\f32b",
);
