// Copyright 2017 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

@import "~@blueprintjs/core/src/common/variables";
@import "../select/select";

.#{$ns}-multi-select {
  min-width: $pt-grid-size * 15;
}

.#{$ns}-multi-select-popover {
  .#{$ns}-menu {
    max-height: $select-popover-max-height;
    max-width: $select-popover-max-width;
    overflow: auto;
  }
}
