// Copyright 2017 Palantir Technologies, Inc. All rights reserved.
// Licensed under the Apache License, Version 2.0.

@import "~@blueprintjs/colors/lib/scss/colors";
@import "~@blueprintjs/core/src/common/variables";
@import "~@blueprintjs/core/src/common/react-transition";

$omnibar-height: 60vh !default;
$omnibar-width: $pt-grid-size * 50 !default;
$omnibar-input-height: $pt-grid-size * 4;

.#{$ns}-omnibar {
  $omnibar-transition-props: (
    filter: (blur($pt-grid-size * 2), blur(0)),
    opacity: (0.2, 1),
  );

  @include react-transition(
    "#{$ns}-overlay",
    $omnibar-transition-props,
    $duration: $pt-transition-duration * 2,
    $easing: $pt-transition-ease,
    $before: "&"
  );
  background-color: $white;
  border-radius: $pt-border-radius;
  box-shadow: $pt-elevation-shadow-4;
  left: calc(50% - #{$omnibar-width / 2});
  top: (100 - $omnibar-height) / 2;
  width: $omnibar-width;
  z-index: $pt-z-index-overlay + 1;

  .#{$ns}-input {
    background-color: transparent;
    border-radius: 0;

    &,
    &:focus {
      box-shadow: none;
    }
  }

  .#{$ns}-menu {
    background-color: transparent;
    border-radius: 0;
    box-shadow: inset 0 1px 0 $pt-divider-black;
    max-height: calc(#{$omnibar-height} - #{$omnibar-input-height});
    overflow: auto;

    &:empty {
      display: none;
    }
  }

  .#{$ns}-dark &,
  &.#{$ns}-dark {
    background-color: $dark-gray4;
    box-shadow: $pt-dark-elevation-shadow-4;
  }
}

.#{$ns}-omnibar-overlay .#{$ns}-overlay-backdrop {
  background-color: rgba($black, 0.2);
}
